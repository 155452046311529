import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Grid,
  DialogActions,
  Select,
  Box,
  MenuItem,
  TextField as Input,
  makeStyles,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { apiCall, displayLog, validate,confirmBox } from "../../common/common";

// styles
import useStyles from "./styles";
import { Button } from "../../components/Wrappers/Wrappers";
import joi from "joi-browser";
import "./styles.css";
// components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// import Input from "@material-ui/core/Input";
import ErrorMessage from "../../components/ErrorMessage";
import profile from "../../static/images/default.png";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import alertify from "alertifyjs";
import { BorderStyle } from "@material-ui/icons";
function AddAccessories(props) {
  const classes = useStyles();
  //STATES
  const [name, setName] = useState("");
  const [productId, setProductId] = useState([]);
  const [type, setType] = useState();
  const [sameAsPerProduct, setSameasPerProduct] = useState("");
  const [independentSize, setIndependentSize] = useState("");
  const [code, setCode] = useState("");
  const [accInitals, setInitals] = useState("");
  const [customXLSize, setCustomXlSize] = useState(false);
  const [customSize, setCustomSize] = useState(false);
  const [status, setStatus] = useState();
  const [isRequired, setIsRequired] = useState(false);
  const [image, setImage] = useState("");
  const [productListing, setProductListing] = useState([]);
  const [sizeforIndependentListing, setSizeForIndependentListing] = useState(
    []
  );
  const [
    sizeforSameAsProductListing,
    setSizeForSameAsProductListing,
  ] = useState([]);
  const [error, setError] = useState(null);
  const [errorSizeList, setErrorSizeList] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [disBtn, setDisBtn] = useState(false);
  const [nextData, setNextData] = useState([]);
  const [sizeData, setSizeData] = useState({});
  const [listItem, setListItem] = useState([]);
  const [addNewSize, setAddNewSize] = useState([]);
  const [sameAsPerProductName, setSameAsPerProductName] = useState([]);
  const [isSpecial, setIsSpecial] = useState(0);
  const [fixSizeList, setFixSizeList] = useState(undefined);
  const [fixSize, setFixSize] = useState([]);
  const [closeIcon, setCloseIcon] = useState(false);
  const [formData, setFormData] = useState();
  const [allowCustomSize , setAllowCustomSize] = useState()
  const [allowCustomXlSize , setAllowCustomXLSize] = useState()

  const history = useHistory();

  useEffect(() => {
    (async () => {
      await getProductList();
      await getSizeforIndependent();
    })();
  }, [error]);

  const getProductList = async () => {
    let res = await apiCall(
      "GET",
      "",
      "/admin/product/get-all-product",
      undefined
    );
    if (res.data.status === true) {
      setProductListing(res.data?.data);
    } else if (res.data.status === false) {
      displayLog(0, res.data.message);
    }
  };

  const getSizeforIndependent = async () => {
    const reqBody = {
      adminSelectList: 2,
    };
    let res = await apiCall("POST", "", "/admin/size/list", reqBody);
    if (res.data.status === true) {
      setSizeForIndependentListing(res.data.data?.size);
    } else if (res.data.status === false) {
      displayLog(0, res.data.message);
    }
  };
  //HANDLE THE FIX SIZE DATA
  const handleFixSize = (e) => {
    const {
      target: { value },
    } = e;
    setFixSizeList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    setError("");
  };
  const handleChangeSize = (itemData) => {
    // setDisBtn(true);
    if (itemData.is_special == 0) {
      setIsSpecial(itemData.is_special);
      if (itemData.parameters.includes("*")) {
        const splitParms = itemData.parameters.split("*");
        let arr = [];
        splitParms.map((item, i) => {
          if (item.includes(" ")) {
            arr.push(item.replace(/ /g, ""));
          } else {
            arr.push(item);
          }
        });
        setNextData([...arr]);
      } else {
        setNextData([itemData.parameters]);
      }
    } else if (itemData.is_special == 1) {
      setNextData([]);

      setIsSpecial(itemData.is_special);
      if (itemData.parameters.includes("*")) {
        const splitParms = itemData.parameters.split("*");
        let arr = [];

        splitParms.map((item, i) => {
          if (item.includes(" ")) {
            arr.push(item.replace(/ /g, ""));
          } else {
            arr.push(item);
          }
        });

        setFixSize([...arr]);
      } else {
        setFixSize([itemData.parameters]);
      }
    }
  };

  const handleInputChange = (event, i) => {
    const { name, value } = event.target;

    setSizeData({
      ...sizeData,
      [name]: value,
    });
  };

  useEffect(() => {
    let bool = {
      data: false,
    };
    listItem.map((item, index) => {
      Object.values(item).map((value) => {
        if (value !== "") {
          setDisBtn(false);
        } else {
          bool.data = true;
        }

        if (bool.data == true) {
          setDisBtn(true);
        }
      });

      // console.log("ITEMMDFDF", aa, "INDEX", aa[index]);
    });
  }, [listItem, sizeData]);

  const handleAddClick = () => {
    let list = [...listItem];

    list.push(sizeData);
    //REMOVE DUPLICATE ARRAY OBJECT
    const obj = [
      ...new Map(list.map((item) => [JSON.stringify(item), item])).values(),
    ];
    setListItem(obj);
    setDisBtn(false);
    setSizeData("");
  };

  const handleRemoveClick = (index) => {
    const list = [...listItem];

    list.splice(index, 1);
    setListItem(list);
  };

  const handlelistData = (e, index) => {
    const { name, value } = e.target;
    const listedit = [...listItem];

    listedit[index][name] = value;
    setListItem(listedit);
  };

  //HANDLE THE IMAGE
  const handleImage = async (e) => {
    displayLog(2, "Please wait for upload image");
    const Img = e.target.files[0];

    var bodyFormData = new FormData();
    bodyFormData.append("image", Img);
    let reqParams = {
      type: "accessories",
    };
    let res = await apiCall(
      "POST",
      "",
      "/admin/upload-image",
      bodyFormData,
      {},
      reqParams
    );

    if (res.data) {
      setImage(res.data.data?.image);

      setError("");
    } else {
      displayLog(0, res.data.message);
    }
  };
  //HANDLE THE ORDER IN APP
  // const handleChangeOrder=(e,order_in_app)=>{
  //   const {name,value } = e.target;

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  //   console.log("setFormData",setFormData);
  //   console.log("setFormData",formData);

  //   // await HandleOrderInApp(order_in_app);
  //   setError("");
    
  // }

  //API CALL ORDER IN APP
  const handleChangeOrder= async(order)=>{
    const reqData = {
      order_in_app:order
    };  
  }
  //HANDLE THE ACCESSORIES NAME
  const handleChange = (e) => {
    const { name, value } = e.target;
    setName(value);
    setTimeout(() => {
      let str = value;
      let matches = str.match(/\b(\w)/g);
      let acronym = matches.join("");
      setInitals(acronym);
    }, 500);
  };

  const handleCustomeSize = (e)=>{
    // const {name,value} = e.target;
    // allowCustomSize(value);
    setAllowCustomSize(e.target.value);
  }

  const handleCustomeXLSize = (e)=>{
    const {name,value} = e.target;
    allowCustomXlSize(value);
  }
  //HANDLE THE SUBMIT BUTTON
  const handleSubmit = async() => {

    let reqData = {
      name: name,
      product:
        productId[productId.length - 1] == undefined
          ? productId.filter((element, index) => index < productId.length - 1)
          : [],
      type: type,
      //  sameAsPerProduct: sameAsPerProduct,
      //  independentSize: independentSize,
      code: code,
      order_in_app:+formData,
      status: status,
      custom_size_limit:allowCustomSize,
      customxl_size_limit:allowCustomXlSize
    };
    if (type == 1) {
      reqData.sameAsPerProduct = sameAsPerProduct;
    }
    if (type == 2) {
      reqData.independentSize = independentSize;
    }
    if (isSpecial == 1) {
      reqData.fix_size = fixSizeList;
    }
    
    validateFormData(reqData);
  };

  //VALIDATE THE FORMDATA
 
  const validateFormData = (body) => {
    console.log("body1",body);
    let schema = joi.object().keys({
      name: joi
        .string()
        .trim()
        .required(),
      product: joi
        .array()
        .min(1)
        .required(),
      type: joi.required(),
      sameAsPerProduct:
        type == 1
          ? joi
              .array()
              .min(1)
              .required()
          : "",
          
      independentSize:
        type == 2
          ? joi
              .string()
              .trim()
              .required()
          : "",
      fix_size: isSpecial == 1 ? joi.required() : "",

      code: joi
        .string()
        .trim()
        .required(),
      status: joi.required(),
      order_in_app: joi
        .number()
        .required(),

        custom_size_limit:customSize == true ? joi.string().trim()
      .required():"",
      customxl_size_limit:customXLSize == true ? joi.string().trim()
        .required():"",
    });
    console.log("body",body);
    
    joi.validate(body, schema, async (err, value) => {
      console.log("err",err);
     
      if (err) {
        if (
          err.details[0].message !== error ||
          error.details[0].context.key !== errorField
        ) {
          console.log(" err.details[0].message ", err.details[0].message );
          let errorLog = validate(err);
          setError(errorLog.error);
          setErrorField(errorLog.errorField);
        }
      } else {
        setError("");
        setErrorField("");
        const sizeData = [...listItem];

        const joinSize = sizeData.map((item, index) =>
          Object.values(item).join("*")
        );
        console.log("formdata",formData);
             //IF ORDER_IN _APP THEN THIS ONE
        if (formData) {
          console.log("formData",formData);
            const reqBody = {
              order_in_app: +formData,
            };
  
            let res = await apiCall(
              "POST",
              "",
              "/admin/accessories/check-order",
  
              reqBody
            );
            console.log("res.data",res.data);
            if(res.data.code==200){
              if(!res.data.status){
                //SHOW POPUP & ASK TO OVERWRITTE
                alertify.confirm("Are you sure you want to overwrite Order In App?", async(responce) => {
                  if(responce){
                    let reqData = {
                      name: name,
                      product_id: body.product,
                      type: type,
                      code: code.toUpperCase().replace(/ /g, ""),
                      accessory_initial: accInitals.toUpperCase().replace(/ /g, ""),
                      status: status,
                      order_in_app:+formData,
                      image: image,
                      custom_size_limit:allowCustomSize,
                      customxl_size_limit:allowCustomXlSize,
            
                      quantity: isRequired == false ? 0 : isRequired == true ? 1 : 0,
                    };
                    if (type !== 3) {
                      reqData.iscustomsizexl =
                        customXLSize == false ? 0 : customXLSize == true ? 1 : 0;
                      reqData.iscustomsize =
                        customSize == false ? 0 : customSize == true ? 1 : 0;
                    }
                    if (type == 1) {
                      reqData.size = sameAsPerProduct;
                    }
                    if (type == 2) {
                      reqData.size = isSpecial == 1 ? fixSizeList : joinSize;
                    }
                    if (type == 2) {
                      reqData.size_type = independentSize;
                    }
            
                    if (type == 1 || type == 2) {
                      if (
                        reqData.size.length > 0 ||
                        reqData.iscustomsize ||
                        reqData.iscustomsizexl ||
                        reqData.no_size
                      ) {
                        let res = await apiCall(
                          "POST",
                          "",
                          "/admin/accessories/add-accessories",
                          reqData
                        );
                        if (res.data.status === true) {
                          props.ToggleInputModal(false);
                          props.getAccessories();
                          history.push("/app/accessories");
                          displayLog(1, res.data.message);
                        } else if (res.data.status === false) {
                          displayLog(0, res.data.message);
                        }
                      } else {
                        displayLog(
                          0,
                          "Please enter at least one size value or select Custom size or CustomXL size option"
                        );
                      }
                    } else if (type == 3) {
                      let res = await apiCall(
                        "POST",
                        "",
                        "/admin/accessories/add-accessories",
                        reqData
                      );
                      if (res.data.status === true) {
                        props.ToggleInputModal(false);
                        props.getAccessories();
                        history.push("/app/accessories");
                        displayLog(1, res.data.message);
                      } else if (res.data.status === false) {
                        displayLog(0, res.data.message);
                      }
                    }
                
                  }
                }).setHeader('<em>Dreamline</em> ').set('labels', { ok: 'OK', cancel: 'CANCEL' });
                // console.log("OKAYYY", response);
  
                  //ARE YOU OVERWRITE ORDER IN APP THEN THIS ONE(RES==1)
                
              }else{
                 
          let reqData = {
            name: name,
            product_id: body.product,
            type: type,
            code: code.toUpperCase().replace(/ /g, ""),
            accessory_initial: accInitals.toUpperCase().replace(/ /g, ""),
            status: status,
            order_in_app:+formData,
            image: image,
            custom_size_limit:allowCustomSize,
            customxl_size_limit:allowCustomXlSize,
  
            quantity: isRequired == false ? 0 : isRequired == true ? 1 : 0,
          };
          if (type !== 3) {
            reqData.iscustomsizexl =
              customXLSize == false ? 0 : customXLSize == true ? 1 : 0;
            reqData.iscustomsize =
              customSize == false ? 0 : customSize == true ? 1 : 0;
          }
          if (type == 1) {
            reqData.size = sameAsPerProduct;
          }
          if (type == 2) {
            reqData.size = isSpecial == 1 ? fixSizeList : joinSize;
          }
          if (type == 2) {
            reqData.size_type = independentSize;
          }
  
          if (type == 1 || type == 2) {
            if (
              reqData.size.length > 0 ||
              reqData.iscustomsize ||
              reqData.iscustomsizexl ||
              reqData.no_size
            ) {
              let res = await apiCall(
                "POST",
                "",
                "/admin/accessories/add-accessories",
                reqData
              );
              if (res.data.status === true) {
                props.ToggleInputModal(false);
                props.getAccessories();
                history.push("/app/accessories");
                displayLog(1, res.data.message);
              } else if (res.data.status === false) {
                displayLog(0, res.data.message);
              }
            } else {
              displayLog(
                0,
                "Please enter at least one size value or select Custom size or CustomXL size option"
              );
            }
          } else if (type == 3) {
            let res = await apiCall(
              "POST",
              "",
              "/admin/accessories/add-accessories",
              reqData
            );
            if (res.data.status === true) {
              props.ToggleInputModal(false);
              props.getAccessories();
              history.push("/app/accessories");
              displayLog(1, res.data.message);
            } else if (res.data.status === false) {
              displayLog(0, res.data.message);
            }
          }
      
              }
            }
            
          }
         
      }
    });
  };

  //CLOSED THE MENU ITEM
  const handleCloseMenuItem = () => {
    console.log("Products", productId);
    if (productId.length > 0 && productId[productId.length - 1] !== undefined) {
      setProductId(productId);
      getSizeforSameAsProduct();
    } else if (productId.length == 0) {
      setCode("");
    }

    setCloseIcon(false);
  };
  //OPENED THE MENU ITEM
  const handleOpenMenuItem = () => {
    setCloseIcon(true);
  };
  const getSizeforSameAsProduct = async () => {
    setCloseIcon(false);

    if (type == 1) {
      setType("");
      setSizeForSameAsProductListing([]);
    }
    // if (productId.length > 0 && sizeforSameAsProductListing.length > 0) {
    //   setSizeForSameAsProductListing([]);
    // }
    if (productId.length > 0) {
      let reqData = {
        product_id: productId,
      };
      let res = await apiCall(
        "POST",
        "",
        "/admin/accessories/size-list",
        reqData
      );

      //getProductCode(item._id);
      if (res.data.status === true) {
        setSizeForSameAsProductListing(res.data?.data);
      } else if (res.data.status === false) {
        setErrorSizeList(res.data.message);
        // displayLog(0, res.data.message);
      }
    }

    // setType("");
    // setIndependentSize("");
    if (productId.length > 0) {
      getProductCode(productId);
    }

    setError("");
    setErrorField("");
  };

  const getProductCode = async (proId) => {
    const req_data = {
      product_id: proId,
      size_id: type == 1 ? -1 : type == 2 ? +independentSize : 0,
    };

    let res = await apiCall(
      "POST",
      "",
      "/admin/accessories/product-code",
      req_data
    );
    if (res.data.status == true) {
      setCode(res.data?.data.code);
    } else {
      displayLog(0, res.data.message);
    }
  };

  const handleSameAsProduct = (e) => {
    const {
      target: { value },
    } = e;
    setSameAsPerProductName(
      typeof value === "string" ? value.split(",") : value
    );
    setSameasPerProduct(typeof value === "string" ? value.split(",") : value);
    setError("");
  };

  const clearArrayData = async (e) => {
  var productSize = sizeforSameAsProductListing.map(function (obj) {
    return obj.size;
  });
    console.log(productSize)
    setSameasPerProduct(productSize)
    if (productId.length > 0) {
      // await getSizeforSameAsProduct();
      const req_data = {
        product_id:
          productId[productId.length - 1] == undefined
            ? productId.filter((element, index) => index < productId.length - 1)
            : [],
        size_id: -1,
      };

      let res = await apiCall(
        "POST",
        "",
        "/admin/accessories/product-code",
        req_data
      );
      if (res.data.status == true) {
        setCode(res.data?.data.code);
        setIsSpecial(0);
      } else {
        displayLog(0, res.data.message);
      }
    } else {
      setError("Please Select the Product");

      setErrorField("product");
    }
    // if (productId.length !== 1) {
    //   displayLog(0, "Please select at least one Product");
    // }

    if (sizeforSameAsProductListing.length == 0) {
      displayLog(0, errorSizeList);
    }
    setNextData([]);
    setSizeData({});
    setListItem([]);
    setAddNewSize([]);
  };

  const clearArrayDataNoSize = async (e) => {
    if (productId.length > 0) {
      const req_data = {
        product_id:
          productId[productId.length - 1] == undefined
            ? productId.filter((element, index) => index < productId.length - 1)
            : [],
        size_id: 0,
      };

      let res = await apiCall(
        "POST",
        "",
        "/admin/accessories/product-code",
        req_data
      );
      if (res.data.status == true) {
        setCode(res.data?.data.code);
        setIsSpecial(0);
      } else {
        displayLog(0, res.data.message);
      }
    } else {
      setError("Please Select the Product");
      setErrorField("product");
    }
    setSameasPerProduct("");
    setNextData([]);
    setSizeData({});
    setListItem([]);
    setAddNewSize([]);
  };

  //IF SELECT THE INDEPENDT OF SIZE
  const handleGenCode = async (e) => {
    if (productId.length > 0) {
      const req_data = {
        product_id: productId,
        size_id: +e.target.value,
      };

      let res = await apiCall(
        "POST",
        "",
        "/admin/accessories/product-code",
        req_data
      );
      if (res.data.status == true) {
        setCode(res.data?.data.code);
      } else {
        displayLog(0, res.data.message);
      }
    } else {
      setError("Please Select the Product");
      setErrorField("product");
    }
  };
  const abc = productId.filter(
    (element, index) => index < productId.length - 1
  );
  return (
    <>
      <Input
        label="Accessorie Name"
        placeholder={"Name"}
        margin="normal"
        variant="outlined"
        name="name"
        // onChange={(e) => setName(e.target.value)}
        onChange={(e) => handleChange(e)}
        InputProps={{
          classes: {
            underline: classes.InputUnderline,
            input: classes.Input,
          },
        }}
        fullWidth
      />
      {errorField === "name" && <ErrorMessage error={error} />}

      <FormControl
        variant="outlined"
        className={classes.formControl}
        style={{ width: "100%", marginTop: "20px" }}
      >
        <InputLabel id="demo-simple-select-outlined-label">
          Select Product
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Select Product"
          multiple
          onChange={(e) => setProductId(e.target.value)}
          onOpen={() => handleOpenMenuItem()}
          open={closeIcon}
          name="product"
          MenuProps={{
            MenuListProps: { padding: classes.padding },
          }}
          // value={
          //   productId &&
          //   productId.filter((element, index) => index < productId.length - 1)
          // }
          value={
            productId?.[0] !== undefined &&
            productId[productId.length - 1] !== undefined
              ? productId
              : productId.filter(
                  (element, index) => index < productId.length - 1
                )
          }
        >
          <IconButton
            onClick={() => handleCloseMenuItem()}
            style={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>

          {productListing &&
            productListing.map((item, index) => {
              // console.log(sizeforSameAsProductListing);
              return (
                <MenuItem
                  value={item._id}

                  // onClick={() => getSizeforSameAsProduct(item)}
                >
                  {item.product_name}
                </MenuItem>
              );
            })}
        </Select>
        {errorField === "product" && <ErrorMessage error={error} />}
      </FormControl>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        style={{ width: "100%", marginTop: "20px" }}
      >
        <InputLabel id="demo-simple-select-outlined-label">
          Select Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Select Type"
          onChange={(e) => setType(e.target.value)}
          name="type"
          value={type ? type : ""}
        >
          <MenuItem
            value={1}
            onClick={(e) => clearArrayData(1)} // onClose={() => getSizeforSameAsProduct()}
          >
            Same as per product
          </MenuItem>

          <MenuItem value={2}>Independent of size</MenuItem>
          <MenuItem value={3} onClick={() => clearArrayDataNoSize(3)}>
            No size
          </MenuItem>
        </Select>
        {errorField === "type" && <ErrorMessage error={error} />}
      </FormControl>
      {type != 1 && sizeforSameAsProductListing.length > 0 ? (
        <>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%", marginTop: "20px" }}
          >

{/* //CHANGES HERE */}


{/* 
            <InputLabel id="demo-simple-select-outlined-label">
              Select Size of Product
            </InputLabel>

            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              label="Select Size of Product"
              onChange={(e) => handleSameAsProduct(e)}
              multiple
              name="sameAsPerProduct"
              value={sameAsPerProductName}
            >
              {sizeforSameAsProductListing &&
                sizeforSameAsProductListing.map((item, index) => {
                  console.log(item.size);
                  return <MenuItem value={item.size}>{item.size}</MenuItem>;
                })}
            </Select> */}




            {errorField === "sameAsPerProduct" && (
              <ErrorMessage error={error} />
            )}
          </FormControl>{" "}
        </>
      ) : (
        ""
      )}
      {/* {type === 1 ? "":""} */}
      {type === 2 ? (
        <>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%", marginTop: "20px" }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Select Size
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Select Size"
              onChange={(e) => setIndependentSize(e.target.value)}
              onClick={(e) => handleGenCode(e)}
              name="independentSize"
              value={independentSize ? independentSize : ""}
            >
              {sizeforIndependentListing &&
                sizeforIndependentListing.map((item, index) => {
                  return (
                    <MenuItem
                      value={item._id}
                      onClick={() => handleChangeSize(item)}
                    >
                      {item.parameters}
                    </MenuItem>
                  );
                })}
            </Select>
            {errorField === "independentSize" && <ErrorMessage error={error} />}
          </FormControl>{" "}
        </>
      ) : (
        ""
      )}

      <div style={{ width: "100%", display: "inline-block" }}>
        {nextData.length > 0 && (
          <>
            {nextData.map((item, i) => (
              <>
                <div
                  className={"sizeDivField"}
                  key={i}
                  style={{ marginRight: "10px", display: "inline-block" }}
                >
                  <div>
                    <Box width={100}>
                      <Input
                        name={`${item}`}
                        margin="normal"
                        className={classes.inputNumber}
                        min="1"
                        step="1"
                        placeholder={`${item}`}
                        variant="outlined"
                        type="number"
                        value={sizeData && sizeData[item]}
                        onChange={(e) => handleInputChange(e, i)}
                        required
                      />
                      {errorField === "sizeData" && (
                        <ErrorMessage error={error} />
                      )}
                    </Box>
                  </div>
                </div>
              </>
            ))}{" "}
          </>
        )}

        {nextData.length > 0 && (
          <div>
            {" "}
            <Button
              onClick={() => handleAddClick()}
              disabled={
                Object.keys(sizeData).length !== nextData.length ? true : false
              }
            >
              Add
            </Button>
          </div>
        )}
        {isSpecial == 1 && (
          <>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "8px", width: "auto" }}
            >
              <Grid item md={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ width: "100%" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Fix Size
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Fix Size"
                    onChange={(e) => handleFixSize(e)}
                    name="fix_size"
                    multiple
                    value={fixSizeList || []}
                    // onChange={(e) =>
                    //   setFormValues({ ...formValues, status: e.target.value })
                    // }
                  >
                    {fixSize &&
                      fixSize.map((item, index) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                  </Select>
                  {errorField === "fix_size" && <ErrorMessage error={error} />}
                </FormControl>{" "}
              </Grid>
            </Grid>
          </>
        )}
      </div>

      {/* For Add New Size  */}
      <div>
        {listItem.length > 0 &&
          listItem.map((item, i) => {
            return (
              <>
                <div
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  {Object.keys(item).map((val) => {
                    return (
                      <>
                        <div
                          className={"sizeDivField"}
                          key={i}
                          style={{
                            marginRight: "10px",
                            display: "inline-block",
                          }}
                        >
                          <div>
                            <Box width={100}>
                              <Input
                                name={`${val}`}
                                margin="normal"
                                className={classes.inputNumber}
                                min="1"
                                step="1"
                                placeholder={`${val}`}
                                variant="outlined"
                                type="number"
                                value={[item[val]]}
                                onChange={(e) => handlelistData(e, i)}
                                required
                              />
                            </Box>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div>
                    <Button onClick={() => handleRemoveClick(i)}>Remove</Button>
                  </div>
                </div>
              </>
            );
          })}
      </div>

      <Input
        label="Code"
        placeholder={"Code"}
        margin="normal"
        variant="outlined"
        name="code"
        InputProps={{
          classes: {
            underline: classes.InputUnderline,
            input: classes.Input,
          },
        }}
        onChange={(e) => setCode(e.target.value)}
        inputProps={{ style: { textTransform: "uppercase" } }}
        value={code ? code : ""}
        fullWidth
      />
      {errorField === "code" && <ErrorMessage error={error} />}
      <Input
        label="Accessories Initals"
        placeholder={"Accessories Initals"}
        margin="normal"
        variant="outlined"
        name="accessory_initial"
        InputProps={{
          classes: {
            underline: classes.InputUnderline,
            input: classes.Input,
          },
        }}
        onChange={(e) => setInitals(e.target.value)}
        inputProps={{ style: { textTransform: "uppercase" } }}
        value={accInitals ? accInitals : ""}
        fullWidth
      />
      {errorField === "accessory_initial" && <ErrorMessage error={error} />}
      <Input
              id="outlined-number"
              label="Order In App"
              placeholder={"Order In App"}
              margin="normal"
              variant="outlined"
              name="order_in_app"
              onChange={(e) => setFormData(e.target.value)}
              onClick={(e)=> handleChangeOrder(e)}
              

              // value={formData.order_in_app}
              type={"number"}
              // inputProps={{ max: 3 }} // sets the maximum length to 10
            ></Input>
            {errorField === "order_in_app" && <ErrorMessage error={error} />}

      {type !== 3 && isSpecial != 1 ? (
        <>
          <Grid container spacing={2}>
            <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                className={classes.input}
                style={{ width: "100%", marginTop: "20px" }}
                InputProps={{
                  classes: {
                    underline: classes.InputUnderline,
                    input: classes.Input,
                  },
                }}
                control={
                  <Checkbox
                    name="iscustomsizexl"
                    color="primary"
                    checked={customXLSize == true ? true : false}
                    onChange={(e) => setCustomXlSize(e.target.checked)}
                  />
                }
                label="Allow Custom XL size"
              />
            </Grid>
            {customXLSize == true ?(
              <>
                <Input
              id="outlined-number"
              label="Custom XL Size Limit"
              placeholder={"Custom XL Size Limit"}
              margin="normal"
              variant="outlined"
              name="allowCustomXlSize"
              onChange={(e) => setAllowCustomXLSize(e.target.value)}
              value={allowCustomXlSize}
              // type="number"
            ></Input>
            {errorField === "allowCustomXlSize" && <ErrorMessage error={error} />}
              </>
            ):null}

            <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                className={classes.input}
                style={{ width: "100%", marginTop: "20px" }}
                InputProps={{
                  classes: {
                    underline: classes.InputUnderline,
                    input: classes.Input,
                  },
                }}
                control={
                  <Checkbox
                    name="iscustomsize"
                    color="primary"
                    checked={customSize == true ? true : false}
                    onChange={(e) => setCustomSize(e.target.checked)}
                  />
                }
                label="Allow Custom Size"
              />
            </Grid>
            {customSize == true ?(
              <>
                <Input
              id="outlined-number"
              label="Custom  Size Limit"
              placeholder={"Custom  Size Limit"}
              margin="normal"
              variant="outlined"
              name="allowCustomSize"
              onChange={e=> setAllowCustomSize(e.target.value)}
              // type="number"
              value={allowCustomSize}
            ></Input>
            {errorField === "allowCustomSize" && <ErrorMessage error={error} />}
              </>
            ):null}
          </Grid>
        </>
      ) : (
        ""
      )}

      <Grid container spacing={2}>
        <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%", marginTop: "20px" }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Accessorie"
              name="status"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={0}>Deactivated</MenuItem>
            </Select>
            {errorField === "status" && <ErrorMessage error={error} />}
          </FormControl>
        </Grid>
        <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            className={classes.input}
            style={{ marginTop: "5px" }}
            InputProps={{
              classes: {
                underline: classes.InputUnderline,
                input: classes.Input,
              },
            }}
            control={
              <Checkbox
                color="primary"
                checked={isRequired == true ? true : false}
                onChange={(e) => setIsRequired(e.target.checked)}
              />
            }
            label="Is accessories required Qty.?"
          />
          {errorField === "is_required" && <ErrorMessage error={error} />}
        </Grid>
      </Grid>

      <InputLabel
        id="demo-simple-select-outlined-label"
        style={{ marginTop: "20px", float: "left", marginRight: "5px" }}
      >
        Image
      </InputLabel>
      <input
        style={{ marginTop: "20px", marginLeft: "5px", float: "none" }}
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        name="image"
        onChange={(e) => handleImage(e)}
        margin="normal"
        variant="outlined"
        InputProps={{
          classes: {
            underline: classes.InputUnderline,
            input: classes.Input,
          },
        }}
      />
      {errorField === "image" && <ErrorMessage error={error} />}
      <div></div>
      {image ? (
        <img
          src={image ? image : profile}
          style={{
            height: "100px",
            width: "100px",
            marginTop: "16px",
            borderRadius: "20px",
          }}
        />
      ) : (
        <div></div>
      )}
      <DialogActions style={{ padding: "10px 0 20px" }}>
        <Button
          variant={"outlined"}
          color="primary"
          onClick={() => props.ToggleInputModal(false)}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          color="primary"
          onClick={(e) => handleSubmit()}
          disabled={disBtn == true ? true : false}
        >
          Submit
        </Button>
      </DialogActions>
    </>
  );
}

export default withRouter(AddAccessories);