import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Grid,
  DialogActions,
  Select,
  Box,
  MenuItem,
  TextField as Input,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { apiCall, displayLog, validate } from "../../common/common";
// styles
import useStyles from "./styles";
import { Button } from "../../components/Wrappers/Wrappers";
import joi from "joi-browser";

// components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// import Input from "@material-ui/core/Input";
import ErrorMessage from "../../components/ErrorMessage";
import profile from "../../static/images/default.png";
import CloseIcon from "@material-ui/icons/Close";
import alertify from "alertifyjs";

function UpdateAccessories(props) {
  const classes = useStyles();

  // STATES

  const [name, setName] = useState("");
  const [productId, setProductId] = useState([]);
  const [type, setType] = useState(undefined);
  const [sameAsPerProduct, setSameasPerProduct] = useState([]);
 

  const [independentSize, setIndependentSize] = useState("");
  const [code, setCode] = useState("");
  const [customXLSize, setCustomXlSize] = useState(false);
  const [customSize, setCustomSize] = useState(false);
  const [status, setStatus] = useState();
  const [isRequired, setIsRequired] = useState(false);
  const [image, setImage] = useState("");
  const [productListing, setProductListing] = useState([]);
  const [sizeforIndependentListing, setSizeForIndependentListing] = useState([]);
  const [sizeforSameAsProductListing, setSizeForSameAsProductListing] = useState([]);

  const [error, setError] = useState(null);


  const [errorField, setErrorField] = useState(null);
  const [disBtn, setDisBtn] = useState(false);
  const [nextData, setNextData] = useState([]);
  const [sizeData, setSizeData] = useState({});
  const [accInitals, setInitals] = useState("");
  const [listItem, setListItem] = useState([]);
  const [addNewSize, setAddNewSize] = useState([]);
  const [errorSizeList, setErrorSizeList] = useState(null);
  const [sameAsPerProductName, setSameAsPerProductName] = useState([]);
  const [modalLoader, setModalLoader] = useState(false);
  const [isSpecial, setIsSpecial] = useState(0);
  const [fixSizeList, setFixSizeList] = useState(undefined);
  const [fixSize, setFixSize] = useState([]);
  const [closeIcon, setCloseIcon] = useState(false);
  const [orderInApp, setOrderInApp] = useState("");
  const [allowCustomSize, setAllowCustomSize] = useState('');
  const [allowCustomXlSize, setAllowCustomXLSize] = useState('');

  const history = useHistory();

  useEffect(() => {
    (async () => {
      await getProductList();
      await getSizeforIndependent();
      await getAccessoriesById();
    })();
  }, []);


  const getAccessoriesById = async () => {
    const asseId = props?.assessoriesId;
    const req_data = {
      accessories_id: +asseId,
    };
    let res = await apiCall(
      "POST",
      "",
      "/admin/accessories/accessories-by-id",
      req_data
    );


    if (res.data.status === true) {
      console.log("res.data", res.data);
      if (res.data.data) {
        setModalLoader(true);
        setName(res.data?.data.name);
        setInitals(res.data?.data.accessory_initial);
        setOrderInApp(res.data?.data.order_in_app);
        setProductId(res.data?.data.product_id);
        // Function Call
        // getSizeforSameAsProduct(res.data?.data.product_id);
        setType(+res.data?.data.type);
        setCode(res.data?.data.code);
        setCustomXlSize(res.data?.data.iscustomsizexl == 1 ? true : false);
        setCustomSize(res.data?.data.iscustomsize == 1 ? true : false);
        setStatus(res.data?.data.status);
        setIsRequired(res.data?.data.quantity == 1 ? true : false);
        setImage(res.data?.data.images ? res.data?.data.images : '');
        setAllowCustomSize(res.data?.data.custom_size_limit);
        setAllowCustomXLSize(res.data?.data.customxl_size_limit);
      }
    } else if (res.data.status === false) {
      displayLog(0, res.data.message);
    }

    //changes here !==

    if (res.data.status === true && res.data?.data.size !== null) {
      console.log("data.size", res.data.data.size);

      if (+res.data?.data.type == 1) {
        setSameasPerProduct(res.data?.data.size);
        let reqData = { product_id: res.data?.data.product_id };
        let response = await apiCall("POST", "", "/admin/accessories/size-list", reqData);
        //getProductCode(item._id);
        if (response.data.status == true) {
          setSizeForSameAsProductListing(response.data?.data);
        }
      }


      //SET THE FIX SIZE LIST
      if (res.data?.data?.is_special == 1 && +res.data?.data.type == 2) {
        setIsSpecial(res.data?.data?.is_special);

        setFixSize(res.data?.data?.size_parameters);
        setNextData([]);
        setFixSizeList(res.data?.data?.size);
        setIndependentSize(res.data?.data.size_type);
      }
      if (res.data?.data?.is_special == 0) {

        if (+res.data?.data.type == 2) {
          const reqBody = {
            adminSelectList: 2,
          };

          let response = await apiCall("POST", "", "/admin/size/list", reqBody);
          if (response.data.status === true) {
            let arr = [];
            let arrData = [];
            setIndependentSize(res.data?.data.size_type);
            var result = response.data.data?.size.map(function (o1) {
              if (o1._id == res.data?.data.size_type) {
                // console.log("ITEMMM", o1.parameters);
                if (o1.parameters && o1.parameters.includes("*")) {
                  const splitParms = o1.parameters && o1.parameters.split("*");
                  // const splitCode = res.data?.data?.size.split("*");
                  const joinSize = res.data?.data?.size.map((item, indexDta) =>
                    item.split("*")
                  );

                  //STE THE KEYS WO PUSH THE DATA
                  splitParms.map((item, i) => {
                    if (item.includes(" ")) {
                      arrData.push(item.replace(/ /g, ""));
                    } else {
                      arrData.push(item);
                    }
                  });

                  setNextData([...arrData]);
                  //LISTITEM FOR KEY & VALUE PAIR
                  joinSize.map((item) => {
                    let obj = {};
                    splitParms.map((parms, index) => {
                      obj[parms] = item[index];
                    });
                    arr.push(obj);
                  });

                  //REMOVE DUPLICATE ARRAY OBJECT
                  const obj = [
                    ...new Map(
                      arr.map((item) => [JSON.stringify(item), item])
                    ).values(),
                  ];
                  setListItem([...obj]);
                } else {
                  const splitParms = o1.parameters && o1.parameters.split("*");

                  const abc = res.data?.data?.size;
                  abc.map((item) => {
                    let obj = {};
                    splitParms.map((parms, index) => {
                      obj[parms] = item;
                    });
                    arr.push(obj);
                  });

                  setNextData([o1.parameters]);
                  setListItem([...arr]);
                }
              }
            });

            // setSizeForIndependentListing(res.data.data?.size);
          } else if (response.data.status === false) {
            displayLog(0, response.data.message);
          }
        }
      }
    }
    setTimeout(() => {
      setModalLoader(false);
    }, 1000);
  };

  const getProductList = async () => {
    setModalLoader(true);
    let res = await apiCall(
      "GET",
      "",
      "/admin/product/get-all-product",
      undefined
    );
    if (res.data.status === true) {
      setProductListing(res.data?.data);
    } else if (res.data.status === false) {
      displayLog(0, res.data.message);
    }
  };

  const getSizeforIndependent = async () => {
    const reqBody = {
      adminSelectList: 2,
    };
    let res = await apiCall("POST", "", "/admin/size/list", reqBody);
    if (res.data.status === true) {
      setSizeForIndependentListing(res.data.data?.size);
    } else if (res.data.status === false) {
      displayLog(0, res.data.message);
    }
  };
  //CLOSED THE MENU ITEM
  const handleCloseMenuItem = () => {
    console.log("Products", closeIcon);
    if (productId.length > 0 && productId[productId.length - 1] !== undefined) {
      setProductId(productId);
      getSizeforSameAsProduct();
    } else if (productId.length == 0) {
      setCode("");
    }
    setCloseIcon(false);
    if (type == 1) {
      setType(undefined);
      // setSizeForSameAsProductListing([]);
      // setSameasPerProduct([])
    }
  };
  //HANDLE CHANGE ORDER
  const handleChangeOrder = (e) => {
    const { name, value } = e.target;
    // console.log("EVENT", e, "name", name);

    setOrderInApp(value);
    setError("");
  };
  //OPENED THE MENU ITEM
  const handleOpenMenuItem = () => {
    setCloseIcon(true);
  };
  const getSizeforSameAsProduct = async () => {
    setCloseIcon(false);

    if (type == 1) {
      setType(undefined);
      // setSizeForSameAsProductListing([]);
    }

    if (productId.length > 0) {
      getProductCode(productId);
    }
    setError("");
    setErrorField("");
    // getProductCode(itemId);
  };

  const getProductCode = async (proId) => {
    const req_data = {
      product_id: proId,
      size_id: type == 1 ? -1 : type == 2 ? +independentSize : 0,
    };

    let res = await apiCall(
      "POST",
      "",
      "/admin/accessories/product-code",
      req_data
    );
    if (res.data.status == true) {
      setCode(res.data?.data.code);
    } else {
      displayLog(0, res.data.message);
    }
  };

  // toast.success(res.data.message)

  // const handleSameAsProduct = (e) => {
  //   const {
  //     target: { value },
  //   } = e;
  //   setSameAsPerProductName(
  //     typeof value === "string" ? value.split(",") : value
  //   );
  //   setSameasPerProduct(typeof value === "string" ? value.split(",") : value);
  //   setError("");
  //   setDisBtn(false);
  // };

  //HANDLE THE FIX SIZE DATA
  const handleFixSize = (e) => {
    const {
      target: { value },
    } = e;
    setFixSizeList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    setError("");
  };
  const handleChangeSize = (itemData) => {
    setListItem([]);
    let arrData = [];
    if (itemData.is_special == 0) {
      setIsSpecial(itemData.is_special);
      if (itemData.parameters.includes("*")) {
        const splitParms = itemData.parameters.split("*");

        let arr = [];
        splitParms.map((item, i) => {
          if (item.includes(" ")) {
            arr.push(item.replace(/ /g, ""));
          } else {
            arr.push(item);
          }
        });

        setNextData([...arr]);
      } else {
        setNextData([itemData.parameters]);
      }
    } else if (itemData.is_special == 1) {
      if (fixSize.length > 0) {
        setFixSizeList([]);
      }
      setNextData([]);

      setIsSpecial(itemData.is_special);
      if (itemData.parameters.includes("*")) {
        const splitParms = itemData.parameters.split("*");
        let arr = [];

        splitParms.map((item, i) => {
          if (item.includes(" ")) {
            arr.push(item.replace(/ /g, ""));
          } else {
            arr.push(item);
          }
        });

        setFixSize([...arr]);
      } else {
        setFixSize([itemData.parameters]);
      }
    }
  };

  const handleInputChange = (event, i) => {
    const { name, value } = event.target;

    setSizeData({
      ...sizeData,
      [name]: value,
    });
  };

  //HANDLE THE ACCESSORIES NAME
  const handleChange = (e) => {
    const { name, value } = e.target;
    setName(value);
    setTimeout(() => {
      let str = value;
      let matches = str.match(/\b(\w)/g);
      let acronym = matches.join("");
      setInitals(acronym);
    }, 500);
  };

  useEffect(() => {
    let bool = {
      data: false,
    };
    listItem.map((item, index) => {
      Object.values(item).map((value) => {
        if (value !== "") {
          setDisBtn(false);
        } else {
          bool.data = true;
        }

        if (bool.data == true) {
          setDisBtn(true);
        }
      });

      // console.log("ITEMMDFDF", aa, "INDEX", aa[index]);
    });
  }, [listItem, sizeData]);

  const handleAddClick = () => {
    let list = [...listItem];
    list.push(sizeData);
    //REMOVE DUPLICATE ARRAY OBJECT
    const obj = [
      ...new Map(list.map((item) => [JSON.stringify(item), item])).values(),
    ];
    setListItem(obj);
    setDisBtn(false);
    setSizeData("");
  };

  const handleRemoveClick = (index) => {
    const list = [...listItem];

    list.splice(index, 1);
    setListItem(list);
  };

  const handlelistData = (e, index) => {
    const { name, value } = e.target;
    const listedit = [...listItem];

    listedit[index][name] = value;
    setListItem(listedit);
  };

  //HANDLE THE IMAGE
  const handleImage = async (e) => {
    displayLog(2, "Please wait for upload image");
    const Img = e.target.files[0];

    var bodyFormData = new FormData();
    bodyFormData.append("image", Img);
    let reqParams = {
      type: "accessories",
    };
    let res = await apiCall(
      "POST",
      "",
      "/admin/upload-image",
      bodyFormData,
      {},
      reqParams
    );

    if (res.data) {
      setImage(res.data.data?.image);

      setError("");
    } else {
      displayLog(0, res.data.message);
    }
  };

  //HANDLE THE SUBMIT BUTTON
  const handleSubmit = () => {
    let reqData = {
      name: name,
      product:
        productId[productId.length - 1] == undefined
          ? productId.filter((element, index) => index < productId.length - 1)
          : productId.length > 0 &&
            productId[productId.length - 1] !== undefined
            ? productId
            : [],
      type: type,
      code: code,
      status: status,
      order_in_app: +orderInApp,
      custom_size_limit: allowCustomSize,
      customxl_size_limit: allowCustomXlSize,
    };
    // if(customXLSize ==true){
    //   reqData["custom_size_limit"] =allowCustomSize
    // }
    // if(customSize ==true){
    //   reqData["customxl_size_limit"] =allowCustomXlSize
    // }
    //CHANGES HERE

    if (type == 1) {
      reqData.sameAsPerProduct = sameAsPerProduct;
    }

    if (type == 2) {
      reqData.independentSize = independentSize;
    }
    if (isSpecial == 1) {
      reqData.fix_size = fixSizeList;
    }
    validateFormData(reqData);
  };

  //VALIDATE THE FORMDATA
  const validateFormData = (body) => {
    console.log("validate data >>>>>", body);
    let schema = joi.object().keys({
      name: joi.string().trim().required(),
      product: joi.array().min(1).required(),
      type: joi.required(),
      sameAsPerProduct:
        type == 1 ? joi.array().min(1).required()
          : joi.optional(),
      independentSize:
        type == 2 ? joi.string().trim().required()
          : "",
      fix_size: isSpecial == 1 ? joi.required() : "",

      code: joi.string().trim().required(),
      status: joi.required(),

      // order:joi
      //       .number()
      //       .trim()
      //       .required(),

      order_in_app: joi.number().required(),
      customxl_size_limit: customXLSize == true ? joi.string().trim().required() : joi.optional(),
      custom_size_limit: customSize == true ? joi.string().trim().required() : joi.optional(),
    });

    console.log("Schemaaaaa", schema)
    console.log("bodyyyy", body)

    joi.validate(body, schema, async (err, value) => {

      console.log("joi validate error -------------->>>>>>>>>>>", err);
      if (err) {
        if (
          err.details[0].message !== error ||
          error.details[0].context.key !== errorField
        ) {
          let errorLog = validate(err);
          console.log("errorLog errorLog errorLog errorLog", errorLog)
          setError(errorLog.error);
          setErrorField(errorLog.errorField);
          displayLog(0, errorLog.error)
        }
      } else {
        console.log("INside error");
        setError("");
        setErrorField("");
        const sizeData = [...listItem];

        const joinSize = sizeData.map((item, index) =>
          Object.values(item).join("*")
        );
        //IF ORDER_IN _APP THEN THIS ONE
        console.log("orderInApp===>", orderInApp);
        if (orderInApp) {
          const reqBody = {
            order_in_app: +orderInApp,
          };
          console.log("orderInApp....>", reqBody);
          let res = await apiCall(
            "POST",
            "",
            "/admin/accessories/check-order",
            reqBody
          );
          console.log("res.data......", res);
          if (res.data.code == 200) {
            if (!res.data.status) {
              //show popup & ask to overwrite
              alertify
                .confirm(
                  "Are you sure you want to overwrite Order In App?",
                  async (response) => {
                    if (response) {
                      const asseId = props?.assessoriesId;

                      let reqData = {
                        accessories_id: asseId,
                        name: name,
                        product_id: body.product,
                        type: type,
                        code: code.toUpperCase().replace(/ /g, ""),
                        accessory_initial: accInitals
                          .toUpperCase()
                          .replace(/ /g, ""),
                        status: status,
                        image: image,
                        order_in_app: +orderInApp,
                        custom_size_limit: allowCustomSize,
                        customxl_size_limit: allowCustomXlSize,

                        quantity:
                          isRequired == false ? 0 : isRequired == true ? 1 : 0,
                      };
                      console.log("reqData", reqData);
                      console.log("type", type);
                      if (type !== 3) {
                        reqData.iscustomsizexl =
                          customXLSize == false ? 0
                            : customXLSize == true ? 1
                              : 0;
                        reqData.iscustomsize =
                          customSize == false ? 0
                            : customSize == true ? 1
                              : 0;
                      }
                      if (type == 1) {
                        reqData.size = sameAsPerProduct;
                        console.log("sameAsPerProduct", sameAsPerProduct);
                        // reqData.size_type = 1;
                      }
                      if (type == 2) {
                        reqData.size = isSpecial == 1 ? fixSizeList : joinSize;
                        reqData.size_type = independentSize;
                      }
                   
                      console.log("reqData..............>>>655", reqData)
                     

                      if (type == 1 || type == 2) {
                        console.log("in type 2 condition ", reqData)
                        if (reqData.size.length >= 0 || reqData.iscustomsize || reqData.iscustomsizexl || reqData.no_size) {
                          console.log("in update condition")
                          let res = await apiCall(
                            "PUT",
                            "",
                            "/admin/accessories/update-accessories",
                            reqData
                          );
                          if (res.data.status === true) {
                            setTimeout(() => {
                              history.push("/app/accessories");
                              props.ToggleInputModal(false);
                              props.getAccessories();
                              displayLog(1, res.data.message);
                            }, 1000);
                          } else if (res.data.status === false) {
                            displayLog(0, res.data.message);
                          }
                        }

                        else {
                          displayLog(0, "Please enter at least one size value or select Custom size or CustomXL size option");
                        }
                      }
                      else if (type == 3) {
                        let res = await apiCall(
                          "PUT",
                          "",
                          "/admin/accessories/update-accessories",
                          reqData
                        );
                        if (res.data.status === true) {
                          setTimeout(() => {
                            history.push("/app/accessories");
                            props.ToggleInputModal(false);
                            props.getAccessories();
                            displayLog(1, res.data.message);
                          }, 1000);
                        } else if (res.data.status === false) {
                          displayLog(0, res.data.message);
                        }
                      }
                    }
                  }
                )
                .setHeader("<em>Dreamline</em> ")
                .set("labels", { ok: "OK", cancel: "CANCEL" });
            }


            else {
              const asseId = props?.assessoriesId;

              let reqData = {
                accessories_id: asseId,
                name: name,
                product_id: body.product,
                type: type,
                code: code.toUpperCase().replace(/ /g, ""),
                accessory_initial: accInitals.toUpperCase().replace(/ /g, ""),
                status: status,
                image: image,
                order_in_app: +orderInApp,
                custom_size_limit: allowCustomSize,
                customxl_size_limit: allowCustomXlSize,

                quantity: isRequired == false ? 0 : isRequired == true ? 1 : 0,
              };
              console.log("reqData", reqData);
              console.log("type", type);
              if (type !== 3) {
                reqData.iscustomsizexl =
                  customXLSize == false ? 0 : customXLSize == true ? 1 : 0;
                reqData.iscustomsize =
                  customSize == false ? 0 : customSize == true ? 1 : 0;
              }
              if (type == 1) {
                reqData.size = sameAsPerProduct;
              }
              if (type == 2) {
                reqData.size = isSpecial == 1 ? fixSizeList : joinSize;
              }
              if (type == 2) {
                reqData.size_type = independentSize;
              }
              console.log("request data", reqData)

              if (type == 1 || type == 2) {
                if (
                  reqData.size.length >= 0 ||
                  reqData.iscustomsize ||
                  reqData.iscustomsizexl ||
                  reqData.no_size
                ) {
                  let res = await apiCall(
                    "PUT",
                    "",
                    "/admin/accessories/update-accessories",
                    reqData
                  );
                  if (res.data.status === true) {
                    setTimeout(() => {
                      history.push("/app/accessories");
                      props.ToggleInputModal(false);
                      props.getAccessories();
                      displayLog(1, res.data.message);
                    }, 1000);
                  } else if (res.data.status === false) {
                    displayLog(0, res.data.message);
                  }
                }
                else {
                  displayLog(0, "Please enter at least one size value or select Custom size or CustomXL size option");
                }
              } else if (type == 3) {
                let res = await apiCall(
                  "PUT",
                  "",
                  "/admin/accessories/update-accessories",
                  reqData
                );
                if (res.data.status === true) {
                  setTimeout(() => {
                    history.push("/app/accessories");
                    props.ToggleInputModal(false);
                    props.getAccessories();
                    displayLog(1, res.data.message);
                  }, 1000);
                } else if (res.data.status === false) {
                  displayLog(0, res.data.message);
                }
              }
            }
          }
        }
      }
    });
  };

  const clearArrayData = async () => {
    var productSize = await sizeforSameAsProductListing.map((obj) => {
      return obj.size;
    });
   
    if (productId.length > 0) {
      const req_data = {
        product_id:
          productId[productId.length - 1] == undefined
            ? productId.filter((element, index) => index < productId.length - 1)
            : productId.length > 0 &&
              productId[productId.length - 1] !== undefined
              ? productId
              : [],
        size_id: -1,
      };

      let res = await apiCall(
        "POST",
        "",
        "/admin/accessories/product-code",
        req_data
      );
      if (res.data.status == true) {
        setCode(res.data?.data.code);
        setIsSpecial(0);
      } else {
        displayLog(0, res.data.message);
      }
      //PER PRODUCT SELECT
      if (productId.length > 0) {
        let reqData = {
          product_id:
            productId[productId.length - 1] == undefined
              ? productId.filter(
                (element, index) => index < productId.length - 1
              )
              : productId.length > 0 &&
                productId[productId.length - 1] !== undefined
                ? productId
                : [],
        };
        let response = await apiCall(
          "POST",
          "",
          "/admin/accessories/size-list",
          reqData
        );

        //getProductCode(item._id);
        if (response.data.status === true) {
          setSizeForSameAsProductListing(response.data?.data);
        } else if (response.data.status === false) {
          displayLog(0, response.data.message);
        }
      }
    } else {
      setError("Please Select the Product");
      setErrorField("product");
    }
    // if (productId.length !== 1) {
    //   displayLog(0, "Please select only one Product");
    // }
    // if (sizeforSameAsProductListing.length == 0) {
    //   displayLog(0, errorSizeList);
    // }
    setNextData([]);
    setSizeData({});
    setListItem([]);
    setAddNewSize([]);
    setFixSizeList([]);
  };

  // const clearArrayDataNoSize = async () => {

  //   if (productId.length > 0) {
  //     const req_data = {
  //       product_id:
  //         productId[productId.length - 1] == undefined
  //           ? productId.filter((element, index) => index < productId.length - 1)
  //           : productId.length > 0 &&
  //             productId[productId.length - 1] !== undefined
  //             ? productId
  //             : [],
  //       size_id: 0,
  //     };

  //     let res = await apiCall(
  //       "POST",
  //       "",
  //       "/admin/accessories/product-code",
  //       req_data
  //     );
  //     if (res.data.status == true) {
  //       setCode(res.data?.data.code);
  //       setIsSpecial(0);
  //     } else {
  //       displayLog(0, res.data.message);
  //     }
  //   } else {
  //     setError("Please Select the Product");
  //     setErrorField("product");
  //   }
  //   setNextData([]);
  //   setSizeData({});
  //   setListItem([]);
  //   setAddNewSize([]);
  //   setFixSizeList([]);
  // };
  //IF SELECT THE INDEPENDT OF SIZE
  const handleGenCode = async (e) => {
    if (productId.length > 0) {
      const req_data = {
        product_id:
          productId[productId.length - 1] == undefined
            ? productId.filter((element, index) => index < productId.length - 1)
            : productId.length > 0 &&
              productId[productId.length - 1] !== undefined
              ? productId
              : [],
        size_id: +e.target.value,
      };

      let res = await apiCall(
        "POST",
        "",
        "/admin/accessories/product-code",
        req_data
      );
      if (res.data.status == true) {
        setCode(res.data?.data.code);
      } else {
        displayLog(0, res.data.message);
      }
    } else {
      setError("Please Select the Product");
      setErrorField("product");
    }
  };

  if (modalLoader == true) {
    console.log("orderInApp?.order_in_app ", orderInApp);
    return (
      <div style={{ textAlign: "center", display: "flex" }}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100vw"}
          height={"calc(100vh - 200px)"}
        >
          <CircularProgress size={50} />
        </Box>
      </div>
    );
  } else {
    console.log("orderInApp", orderInApp);
    return (
      <>
        <Input
          label="Accessorie Name"
          placeholder={"Name"}
          margin="normal"
          variant="outlined"
          name="name"
          onChange={(e) => handleChange(e)}
          InputProps={{
            classes: {
              underline: classes.InputUnderline,
              input: classes.Input,
            },
          }}
          value={name ? name : ""}
          fullWidth
        />
        {errorField === "name" && <ErrorMessage error={error} />}

        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{
            width: "100%",
            marginTop: "20px",
          }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Select Product
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Select Product"
            multiple
            onChange={(e) => setProductId(e.target.value)}
            // onClose={() => getSizeforSameAsProduct()}
            name="product"
            onOpen={() => handleOpenMenuItem()}
            open={closeIcon}
            value={
              productId.length > 0 &&
                productId[productId.length - 1] !== undefined
                ? productId
                : productId.filter( (element, index) => index < productId.length - 1 )
            }
          >
            <IconButton
              onClick={() => handleCloseMenuItem()}
              style={{ float: "right" }}
            >
              <CloseIcon />
            </IconButton>

            {productListing &&
              productListing.map((item, index) => {
                return (
                  <MenuItem
                    value={item._id}
                  // onClick={() => getSizeforSameAsProduct(item._id)}
                  >
                    {item.product_name}
                  </MenuItem>
                );
              })}
          </Select>
          {errorField === "product" && <ErrorMessage error={error} />}
        </FormControl>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{
            width: "100%",
            marginTop: "20px",
          }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Select Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Select Type"
            name="type"
            value={type == 1 ? 1 : type == 2 ? 2 : type == 3 ? 3 : undefined}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem
              value={1}
              onClick={(e) => clearArrayData()} // onClose={() => getSizeforSameAsProduct()}   //CHANGES HERE
            >
              Same as per product
            </MenuItem>
            <MenuItem value={2} onClick={() => setIndependentSize("")}  >
              Independent of size
            </MenuItem>
            <MenuItem value={3}
              // onClick={() => clearArrayDataNoSize()}            
              onClick={(e) => clearArrayData()}                   //CHANGES HERE
            >
              No size
            </MenuItem>
          </Select>
          {errorField == "type" && <ErrorMessage error={error} />}
        </FormControl>


        {type === 2 && sizeforSameAsProductListing.length > 0 ? (
          <>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "100%", marginTop: "20px" }}
            >


              {/* CHANGES HERE */}

              {/* <InputLabel id="demo-simple-select-outlined-label">
                Select Size of Product
              </InputLabel>
          
              <Select
          
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                label="Select Size of Product"
                onChange={(e) => handleSameAsProduct(e)}
                multiple
                name="sameAsPerProduct"
                value={sameAsPerProductName}
                
          >
                {sizeforSameAsProductListing &&
                  sizeforSameAsProductListing.map((item, index) => {
                    return <MenuItem value={item.size}>{item.size}</MenuItem>;
                  })}
                  
              </Select> */}

              {/* {errorField == "type" && (
                <ErrorMessage error={error} />
              )} */}
            </FormControl>{" "}
          </>
        ) : (
          ""
        )}

        {type == 2 ? (
          <>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "100%", marginTop: "20px" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Select Size
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Select Size"
                onChange={(e) => setIndependentSize(e.target.value)}
                onClick={(e) => handleGenCode(e)}
                name="independentSize"
                value={independentSize ? independentSize : ""}
              >
                {sizeforIndependentListing &&
                  sizeforIndependentListing.map((item, index) => {
                    return (
                      <MenuItem
                        value={item._id}
                        onClick={() => handleChangeSize(item)}
                      >
                        {item.parameters}
                      </MenuItem>
                    );
                  })}
              </Select>
              {errorField == "independentSize" && (
                <ErrorMessage error={error} />
              )}
            </FormControl>{" "}
          </>
        ) : (
          ""
        )}

        <div
          style={{
            width: "100%",
            display: "inline-block",
          }}
        >
          {nextData?.length > 0 && (
            <>
              {nextData.map((item, i) => (
                <>
                  <div
                    className={"sizeDivField"}
                    key={i}
                    style={{
                      marginRight: "10px",
                      display: "inline-block",
                    }}
                  >
                    <div>
                      <Box width={100}>
                        <Input
                          name={`${item}`}
                          margin="normal"
                          className={classes.inputNumber}
                          min="1"
                          step="1"
                          placeholder={`${item}`}
                          variant="outlined"
                          type="number"
                          value={sizeData && sizeData[item]}
                          onChange={(e) => handleInputChange(e, i)}
                          required
                        />
                        {errorField === "sizeData" && (
                          <ErrorMessage error={error} />
                        )}
                      </Box>
                    </div>
                  </div>
                </>
              ))}{" "}
            </>
          )}
          {nextData?.length > 0 && (
            <div>
              {" "}
              <Button
                onClick={() => handleAddClick()}
                disabled={
                  Object.keys(sizeData).length !== nextData.length
                    ? true
                    : false
                }
              >
                Add
              </Button>
            </div>
          )}

          {isSpecial == 1 && (
            <>
              <Grid
                container
                spacing={2}
                style={{ marginTop: "8px", width: "auto" }}
              >
                <Grid item md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ width: "100%" }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select Fix Size
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Fix Size"
                      onChange={(e) => handleFixSize(e)}
                      name="fix_size"
                      multiple
                      value={fixSizeList || []}
                    // onChange={(e) =>
                    //   setFormValues({ ...formValues, status: e.target.value })
                    // }
                    >
                      {fixSize &&
                        fixSize.map((item, index) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                    </Select>
                    {errorField === "fix_size" && (
                      <ErrorMessage error={error} />
                    )}
                  </FormControl>{" "}
                </Grid>
              </Grid>
            </>
          )}
        </div>

        {/* For Add New Size  */}
        <div>
          {listItem.length > 0 &&
            listItem.map((item, i) => {
              return (
                <>
                  <div
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    {Object.keys(item).map((val) => {
                      return (
                        <>
                          <div
                            className={"sizeDivField"}
                            key={i}
                            style={{
                              marginRight: "10px",
                              display: "inline-block",
                            }}
                          >
                            <div>
                              <Box width={100}>
                                <Input
                                  name={`${val}`}
                                  margin="normal"
                                  className={classes.inputNumber}
                                  min="1"
                                  step="1"
                                  placeholder={`${val}`}
                                  variant="outlined"
                                  type="number"
                                  value={[item[val]]}
                                  onChange={(e) => handlelistData(e, i)}
                                  required
                                />
                              </Box>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div>
                      <Button onClick={() => handleRemoveClick(i)}>
                        Remove
                      </Button>
                    </div>
                  </div>
                </>
              );
            })}
        </div>

        <Input
          label="Code"
          placeholder={"Code"}
          margin="normal"
          variant="outlined"
          name="code"
          InputProps={{
            classes: {
              underline: classes.InputUnderline,
              input: classes.Input,
            },
          }}
          onChange={(e) => setCode(e.target.value)}
          inputProps={{ style: { textTransform: "uppercase" } }}
          value={code ? code : ""}
          fullWidth
        />
        {errorField === "code" && <ErrorMessage error={error} />}

        <Input
          label="Accessories Initals"
          placeholder={"Accessories Initals"}
          margin="normal"
          variant="outlined"
          name="accessory_initial"
          InputProps={{
            classes: {
              underline: classes.InputUnderline,
              input: classes.Input,
            },
          }}
          onChange={(e) => setInitals(e.target.value)}
          inputProps={{ style: { textTransform: "uppercase" } }}
          value={accInitals ? accInitals : ""}
          fullWidth
        />
        {errorField === "accessory_initial" && <ErrorMessage error={error} />}

        {type !== 3 && isSpecial != 1 ? (
          <>
            <Grid container spacing={2}>
              <Grid
                item
                md={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  className={classes.input}
                  style={{ width: "100%", marginTop: "20px" }}
                  InputProps={{
                    classes: {
                      underline: classes.InputUnderline,
                      input: classes.Input,
                    },
                  }}
                  control={
                    <Checkbox
                      name="iscustomsizexl"
                      color="primary"
                      checked={customXLSize == true ? true : false}
                      onChange={(e) => setCustomXlSize(e.target.checked)}
                    />
                  }
                  label="Allow Custom XL size"
                />
              </Grid>

              <Grid
                item
                md={6}
              >
                {customXLSize == true ? (
                  <>
                    <Input
                      id="outlined-number"
                      label="Custom XL Size Limit"
                      placeholder={"Custom XL Size Limit"}
                      margin="normal"
                      variant="outlined"
                      name="customxl_size_limit"
                      onChange={(e) => setAllowCustomXLSize(e.target.value)}
                      value={allowCustomXlSize}

                    ></Input>
                    {errorField == "customxl_size_limit" && (
                      <ErrorMessage error={error} />
                    )}
                  </>
                ) : ''}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                item
                md={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  className={classes.input}
                  style={{ width: "100%", marginTop: "20px" }}
                  InputProps={{
                    classes: {
                      underline: classes.InputUnderline,
                      input: classes.Input,
                    },
                  }}
                  control={
                    <Checkbox
                      name="iscustomsize"
                      color="primary"
                      checked={customSize == true ? true : false}
                      onChange={(e) => setCustomSize(e.target.checked)}
                    />
                  }
                  label="Allow Custom Size"
                />
              </Grid>

              <Grid
                item
                md={6}
              >

                {customSize == true ? (
                  <>
                    <Input
                      id="outlined-number"
                      label="Custom  Size Limit"
                      placeholder={"Custom  Size Limit"}
                      margin="normal"
                      variant="outlined"
                      name="custom_size_limit"
                      onChange={(e) => setAllowCustomSize(e.target.value)}
                      // type="number"
                      value={allowCustomSize ? allowCustomSize : ''}
                    ></Input>

                    {errorField == "custom_size_limit" && (
                      <ErrorMessage error={error} />
                    )}
                  </>
                ) : null}
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )
        }

        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{
                width: "100%",
                marginTop: "20px",
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Accessorie"
                name="status"
                value={status == 1 ? 1 : 0}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>Deactivated</MenuItem>
              </Select>
              {errorField === "status" && <ErrorMessage error={error} />}
            </FormControl>
          </Grid>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              className={classes.input}
              style={{ marginTop: "5px" }}
              InputProps={{
                classes: {
                  underline: classes.InputUnderline,
                  input: classes.Input,
                },
              }}
              control={
                <Checkbox
                  color="primary"
                  checked={isRequired == 1 ? true : false}
                  onChange={(e) => setIsRequired(e.target.checked)}
                />
              }
              label="Is accessories required Qty.?"
            />
            {errorField === "quantity" && <ErrorMessage error={error} />}
          </Grid>
        </Grid>

        <InputLabel
          id="demo-simple-select-outlined-label"
          style={{
            marginTop: "20px",
            float: "left",
            marginRight: "5px",
          }}
        >
          Image
        </InputLabel>
        <input
          style={{
            marginTop: "20px",
            marginLeft: "5px",
            float: "none",
          }}
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          type="file"
          name="images"
          onChange={(e) => handleImage(e)}
          margin="normal"
          variant="outlined"
          InputProps={{
            classes: {
              underline: classes.InputUnderline,
              input: classes.Input,
            },
          }}
        />
        {errorField === "images" && <ErrorMessage error={error} />}
        <div></div>
        {
          image ? (
            <img
              src={image ? image : profile}
              style={{
                height: "100px",
                width: "100px",
                marginTop: "20px",
                borderRadius: "20px",
              }}
            />
          ) : (
            <div></div>
          )
        }
        <Input
          label="Order In App"
          placeholder={"Order In App"}
          margin="normal"
          variant="outlined"
          name="order_in_app"
          type="number"
          // onBlur={(e) => handleOrder(e)}
          onChange={(e) => setOrderInApp(e.target.value)}
          onClick={(e) => handleChangeOrder(e)}
          InputProps={{
            classes: {
              underline: classes.InputUnderline,
              input: classes.Input,
            },
          }}
          value={orderInApp ? orderInApp : ""}
          fullWidth
        />
        {errorField === "order_in_app" && <ErrorMessage error={error} />}
        <DialogActions style={{ padding: "10px 0 20px" }}>
          <Button
            variant={"outlined"}
            color="primary"
            onClick={() => props.ToggleInputModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color="primary"
            onClick={() => handleSubmit()}
            disabled={disBtn === true ? true : false}
          >
            Submit
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default withRouter(UpdateAccessories);