import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  successIcon: {
    color: theme.palette.success.main
  },
  failIcon: {
    color: "red"
  }
}));
