import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Grid, DialogActions, Select, Box, MenuItem, TextField as Input, ListItem } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { apiCall, displayLog, validate } from '../../common/common';

// styles
import useStyles from './styles';
import { Button } from '../../components/Wrappers/Wrappers';
import joi from 'joi-browser';

// components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// import Input from "@material-ui/core/Input";
import ErrorMessage from '../../components/ErrorMessage';
import { ChromePicker } from 'react-color';
import profile from '../../static/images/default.png';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
function AddProductModification(props) {
  const classes = useStyles();

  //STATES
  const [productId, setProductId] = useState([]);
  const [productListing, setProductListing] = useState([]);
  const [name, setName] = useState('');
  const [accInitals, setInitals] = useState('');

  // SIZESSSS
  const [status, setStatus] = useState();
  const [type, setType] = useState();
  const [customSize, setCustomSize] = useState(false);
  console.log("customSize", customSize);
  const [isModificationRequired, setIsModificationRequired] = useState(false);
  const [customXLSize, setCustomXlSize] = useState(false);
  console.log("customXLSize", customXLSize)

  const [modificationAllow, setModificationAllow] = useState(false);
  const [acessoriesId, setAcessoriesId] = useState([]);
  const [accessoriesListing, setAccessoriesListing] = useState([]);
  const [isColorRequired, setIsColorRequired] = useState(false);
  const [isColorModification, setIsColorModification] = useState(false);
  const [colours, setColours] = useState([]);
  const [code, setCode] = useState('');
  const [image, setImage] = useState('');
  const [errorSizeList, setErrorSizeList] = useState(null);

  const [sameAsPerProduct, setSameasPerProduct] = useState([]);
  const [sizeforSameAsProductListing, setSizeForSameAsProductListing] = useState([]);
  const [independentSize, setIndependentSize] = useState('');
  const [sizeforIndependentListing, setSizeForIndependentListing] = useState([]);

  const [nextData, setNextData] = useState([]);
  const [sizeData, setSizeData] = useState({});
  const [listItem, setListItem] = useState([]);
  const [addNewSize, setAddNewSize] = useState([]);

  const [error, setError] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [disBtn, setDisBtn] = useState(false);
  const [sameAsPerProductName, setSameAsPerProductName] = useState([]);
  const [isSpecial, setIsSpecial] = useState(0);
  const [fixSizeList, setFixSizeList] = useState([]);
  const [fixSize, setFixSize] = useState([]);
  const [closeIcon, setCloseIcon] = useState(false);
  const [AssCloseIcon, setAssCloseIcon] = useState(false);
  const [allowCustomSize, setAllowCustomSize] = useState();
  const [allowCustomXlSize, setAllowCustomXLSize] = useState();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      await getProductList();
      await getSizeforIndependent();
      await getAccessoriesList();
    })();
  }, [error]);

  const getProductList = async () => {
    let res = await apiCall('GET', '', '/admin/product/get-all-product', undefined);
    console.log('***res.data***', res.data);
    if (res.data.status === true) {
      setProductListing(res.data?.data);
    } else if (res.data.status === false) {
      displayLog(0, res.data.message);
    }
  };

  const getSizeforIndependent = async () => {
    const reqBody = {
      adminSelectList: 2,
    };
    let res = await apiCall('POST', '', '/admin/size/list', reqBody);
    if (res.data.status === true) {
      setSizeForIndependentListing(res.data.data?.size);
    } else if (res.data.status === false) {
      displayLog(0, res.data.message);
    }
  };
  //HANDLE THE FIX SIZE DATA
  const handleFixSize = e => {
    const {
      target: { value },
    } = e;
    setFixSizeList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

    setError('');
  };
  const handleChangeSize = itemData => {
    if (itemData.is_special == 0) {
      setIsSpecial(itemData.is_special);
      if (itemData.parameters.includes('*')) {
        const splitParms = itemData.parameters.split('*');
        let arr = [];
        splitParms.map((item, i) => {
          if (item.includes(' ')) {
            arr.push(item.replace(/ /g, ''));
          } else {
            arr.push(item);
          }
        });
        setNextData([...arr]);
      } else {
        setNextData([itemData.parameters]);
      }
    } else if (itemData.is_special == 1) {
      setNextData([]);

      setIsSpecial(itemData.is_special);
      if (itemData.parameters.includes('*')) {
        const splitParms = itemData.parameters.split('*');
        let arr = [];

        splitParms.map((item, i) => {
          if (item.includes(' ')) {
            arr.push(item.replace(/ /g, ''));
          } else {
            arr.push(item);
          }
        });

        setFixSize([...arr]);
      } else {
        setFixSize([itemData.parameters]);
      }
    }
  };

  const handleInputChange = (event, i) => {
    const { name, value } = event.target;

    setSizeData({
      ...sizeData,
      [name]: value,
    });
  };

  useEffect(() => {
    let bool = {
      data: false,
    };
    listItem.map((item, index) => {
      Object.values(item).map(value => {
        if (value !== '') {
          setDisBtn(false);
        } else {
          bool.data = true;
        }

        if (bool.data == true) {
          setDisBtn(true);
        }
      });

      // console.log("ITEMMDFDF", aa, "INDEX", aa[index]);
    });
  }, [listItem, sizeData]);

  const handleAddClick = () => {
    let list = [...listItem];
    list.push(sizeData);

    //REMOVE DUPLICATE ARRAY OBJECT
    const obj = [...new Map(list.map(item => [JSON.stringify(item), item])).values()];
    setListItem(obj);
    setDisBtn(false);
    setSizeData('');
  };

  const handleRemoveClick = index => {
    const list = [...listItem];

    list.splice(index, 1);
    setListItem(list);
  };

  const handlelistData = (e, index) => {
    const { name, value } = e.target;
    const listedit = [...listItem];

    listedit[index][name] = value;
    setListItem(listedit);
  };

  //HANDLE THE IMAGE
  const handleImage = async e => {
    displayLog(2, 'Please wait for upload image');
    const Img = e.target.files[0];

    var bodyFormData = new FormData();
    bodyFormData.append('image', Img);
    let reqParams = {
      type: 'accessories',
    };
    let res = await apiCall('POST', '', '/admin/upload-image', bodyFormData, {}, reqParams);

    if (res.data) {
      setImage(res.data.data?.image);

      setError('');
    } else {
      displayLog(0, res.data.message);
    }
  };
  //HANDLE THE MODIFICATIION NAME
  const handleChange = e => {
    const { name, value } = e.target;
    setName(value);
    setTimeout(() => {
      let str = value;
      let matches = str.match(/\b(\w)/g);
      let acronym = matches.join('');
      setInitals(acronym);
    }, 500);
  };
  //HANDLE THE SUBMIT BUTTON
  const handleSubmit = () => {
    let reqData = {
      product: productId[productId.length - 1] == undefined ? productId.filter((element, index) => index < productId.length - 1) : [],
      name: name,
      type: type,
      status: status,
      is_color_modification: isColorModification ? 1 : 0,
      code: code,
      color: [],
      custom_size_limit: allowCustomSize,
      customxl_size_limit: allowCustomXlSize,
    };
    if (modificationAllow == true) {
      reqData.accessories = acessoriesId[acessoriesId.length - 1] == undefined ? acessoriesId.filter((element, index) => index < acessoriesId.length - 1) : [];
    }
    if (type == 1) {
      reqData.sameAsPerProduct = sameAsPerProduct;
    }
    if (type == 2) {
      reqData.independentSize = independentSize;
    }
    console.log('isColorModification', isColorModification);
    if (isColorModification == true) {
      reqData.color = colours;
    }
    if (isColorRequired == true) {
      reqData.color = colours;
    } else {
      reqData.color = [];
    }
    if (isSpecial == 1) {
      reqData.fix_size = fixSizeList;
    }
    console.log('PRODUCT', reqData);
    validateFormData(reqData);
  };

  //VALIDATE THE FORMDATA
  const validateFormData = body => {
    let schema = joi.object().keys({
      product: joi
        .array()
        .min(1)
        .required(),
      name: joi
        .string()
        .trim()
        .required(),
      type: joi.required(),
      sameAsPerProduct:
        type == 1
          ? joi
            .array()
            .min(1)
            .required()
          : '',
      fix_size:
        isSpecial == 1
          ? joi
            .array()
            .min(1)
            .required()
          : '',

      independentSize:
        type == 2
          ? joi
            .string()
            .trim()
            .required()
          : '',
      status: joi.required(),
      accessories:
        modificationAllow == true
          ? joi
            .array()
            .min(1)
            .required()
          : '',
      color: joi.array().required(),

      is_color_modification: isColorModification == true ? joi.required() : 0,

      code: joi
        .string()
        .trim()
        .required(),

      custom_size_limit: customSize ? joi.string().trim().required() : joi.optional(),
      customxl_size_limit: customXLSize ? joi.string().trim().required() : joi.optional(),
    });
    console.log('body====');
    joi.validate(body, schema, async (err, value) => {
      if (err) {
        console.log('before error', err);

        if (err.details[0].message !== error || error.details[0].context.key !== errorField) {
          let errorLog = validate(err);
          setError(errorLog.error);
          setErrorField(errorLog.errorField);
        }
      } else {
        console.log('after error');
        setError('');
        setErrorField('');
        const sizeData = [...listItem];

        const joinSize = sizeData.map((item, index) => Object.values(item).join('*'));
        console.log('BODY', body);

        let reqData = {
          product_id: body.product,
          name: name,

          status: status,
          type: type,
          code: code.toUpperCase().replace(/ /g, ''),
          modification_initial: accInitals.toUpperCase().replace(/ /g, ''),
          image: image,
          custom_size_limit: allowCustomSize,
          customxl_size_limit: allowCustomXlSize,

          is_color: isColorRequired == false ? 0 : isColorRequired == true ? 1 : 0,

          allow_accessories: modificationAllow == false ? 0 : modificationAllow == true ? 1 : 0,

          quantity: isModificationRequired == false ? 0 : isModificationRequired == true ? 1 : 0,

          is_color_modification: isColorModification == true ? 1 : 0,
          // isColorModification == false ? 0 : isColorModification == true ? 1 : 0,
        };
        if (modificationAllow == true) {
          reqData.accessories_id = body.accessories;
        }
        if (type !== 3) {
          reqData.iscustomsizexl = customXLSize == false ? 0 : customXLSize == true ? 1 : 0;
          reqData.iscustomsize = customSize == false ? 0 : customSize == true ? 1 : 0;
        }
        if (type == 1) {
          reqData.size = sameAsPerProduct;
        }
        if (type == 2) {
          reqData.size = isSpecial == 1 ? fixSizeList : joinSize;
        }
        if (type == 2) {
          reqData.size_type = independentSize;
        }
        if (isColorRequired == true) {
          reqData.color = colours;
        }
        console.log('REQDATA', reqData);
        console.log('type', type);
        if (type == 1 || type == 2) {
          if (reqData.size.length > 0 || reqData.iscustomsize || reqData.iscustomsizexl || reqData.no_size) {
            let res = await apiCall('POST', '', '/admin/modification/add-modification', reqData);
            console.log('res.data', res.data);
            if (res.data.status === true) {
              props.ToggleInputModal(false);
              props.getModification();
              history.push('/app/modification');
              displayLog(1, res.data.message);
            } else if (res.data.status === false) {
              displayLog(0, res.data.message);
            }
          } else {
            displayLog(0, 'Please enter at least one size value or select Custom size or CustomXL size option');
          }
        } else if (type == 3) {
          let res = await apiCall('POST', '', '/admin/modification/add-modification', reqData);
          if (res.data.status === true) {
            props.ToggleInputModal(false);
            props.getModification();
            history.push('/app/modification');
            displayLog(1, res.data.message);
          } else if (res.data.status === false) {
            displayLog(0, res.data.message);
          }
        }
      }
    });
  };
  //   <FormControlLabel
  //   className={classes.input}
  //   style={{ width: "100%", marginTop: "16px" }}
  //   InputProps={{
  //     classes: {
  //       underline: classes.InputUnderline,
  //       input: classes.Input,
  //     },
  //   }}
  //   control={
  //     <Checkbox
  //       name="iscustomsize"
  //       color="primary"
  //       checked={customSize == true ? true : false}
  //       onChange={(e) => setCustomSize(e.target.checked)}
  //     />
  //   }
  //   label="Allow Custom Size"
  // />
  //CLOSED THE MENU ITEM
  const handleCloseMenuItem = () => {
    if (productId.length > 0 && productId[productId.length - 1] !== undefined) {
      setProductId(productId);
      getSizeforSameAsProduct();
    } else if (productId.length == 0) {
      setCode('');
    }
    setCloseIcon(false);
    setAssCloseIcon(false);
  };
  //OPENED THE MENU ITEM
  const handleOpenMenuItem = () => {
    setCloseIcon(true);
  };
  //HANDLE THE ACCESSIORES MENU ITEM
  const handleOpenAssMenuItem = () => {
    setAssCloseIcon(true);
  };
  const getSizeforSameAsProduct = async item => {
    setCloseIcon(false);

    if (type == 1) {
      setType('');
      setSizeForSameAsProductListing([]);
    }
    if (productId.length > 0) {
      let reqData = {
        product_id: productId,
      };
      let res = await apiCall('POST', '', '/admin/accessories/size-list', reqData);

      //getProductCode(item._id);
      if (res.data.status === true) {
        setSizeForSameAsProductListing(res.data?.data);
      } else if (res.data.status === false) {
        setErrorSizeList(res.data.message);
        // displayLog(0, res.data.message);
      }
    }
    if (productId.length > 0) {
      getProductCode(productId);
    }

    setError('');
    setErrorField('');
  };
  //GENERATE THE PRODUCT MODIFICATION CODE
  const getProductCode = async proId => {
    const req_data = {
      product_id: proId,
      size_id: type == 1 ? -1 : type == 2 ? +independentSize : 0,
    };

    let res = await apiCall('POST', '', '/admin/modification/product-code', req_data);
    console.log('res-->', res.data);
    if (res.data.status == true) {
      setCode(res.data?.data.code);
    } else {
      displayLog(0, res.data.message);
    }
  };
  const getAccessoriesList = async () => {
    const reqBody = {
      type: 'forSelect',
    };

    let res = await apiCall(
      'POST',
      '',
      '/admin/accessories/accessories-list',

      reqBody
    );

    if (res.data.status === true) {
      setAccessoriesListing(res.data?.data);
    } else if (res.data.status === false) {
      displayLog(0, res.data.message);
    }
  };

  const handleSameAsProduct = e => {
    const {
      target: { value },
    } = e;
    //   <FormControlLabel
    //   className={classes.input}
    //   style={{ width: "100%", marginTop: "16px" }}
    //   InputProps={{
    //     classes: {
    //       underline: classes.InputUnderline,
    //       input: classes.Input,
    //     },
    //   }}
    //   control={
    //     <Checkbox
    //       name="iscustomsize"
    //       color="primary"
    //       checked={customSize == true ? true : false}
    //       onChange={(e) => setCustomSize(e.target.checked)}
    //     />
    //   }
    //   label="Allow Custom Size"
    // />
    setSameAsPerProductName(typeof value === 'string' ? value.split(',') : value);
    setSameasPerProduct(typeof value === 'string' ? value.split(',') : value);
    setError('');
  };

  //HANDLER FOR ISCOLOUR MODIFICATION
  const changeValue = async e => {
    setIsColorModification(e.target.checked);
    if (e.target.checked == true) {
      setType(3);
    } else {
      setType('');
    }
    setType(3);
  };

  const clearArrayData = async () => {
    if (productId.length > 0) {
      const req_data = {
        product_id: productId[productId.length - 1] == undefined ? productId.filter((element, index) => index < productId.length - 1) : [],
        size_id: -1,
      };

      let res = await apiCall('POST', '', '/admin/modification/product-code', req_data);
      if (res.data.status == true) {
        setCode(res.data?.data.code);
        setIsSpecial(0);
      } else {
        displayLog(0, res.data.message);
      }
    } else {
      setError('Please Select the Product');
      setErrorField('product');
    }
    // if (productId.length !== 1) {
    //   displayLog(0, "Please select at least one Product");
    // }
    if (sizeforSameAsProductListing.length == 0) {
      displayLog(0, errorSizeList);
    }
    setNextData([]);
    setSizeData({});
    setListItem([]);
    setAddNewSize([]);
  };

  const clearArrayDataNoSize = async () => {
    if (productId.length > 0) {
      const req_data = {
        product_id: productId[productId.length - 1] == undefined ? productId.filter((element, index) => index < productId.length - 1) : [],
        size_id: 0,
      };

      let res = await apiCall('POST', '', '/admin/modification/product-code', req_data);
      if (res.data.status == true) {
        setCode(res.data?.data.code);
        setIsSpecial(0);
      } else {
        displayLog(0, res.data.message);
      }
    } else {
      setError('Please Select the Product');
      setErrorField('product');
    }
    setSameasPerProduct('');
    setNextData([]);
    setSizeData({});
    setListItem([]);
    setAddNewSize([]);
  };

  const clearColorData = () => {
    if (isColorRequired == false) {
      setColours([]);
    }
  };

  const handleColorBox = e => {
    const {
      target: { value },
    } = e;
    setColours(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setError('');
  };
  //IF SELECT THE INDEPENDT OF SIZE
  const handleGenCode = async e => {
    if (productId.length > 0) {
      const req_data = {
        product_id: productId[productId.length - 1] == undefined ? productId.filter((element, index) => index < productId.length - 1) : [],
        size_id: +e.target.value,
      };

      let res = await apiCall('POST', '', '/admin/modification/product-code', req_data);
      if (res.data.status == true) {
        setCode(res.data?.data.code);
      } else {
        displayLog(0, res.data.message);
      }
    } else {
      setError('Please Select the Product');
      setErrorField('product');
    }
  };

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginTop: '16px' }}>
        <InputLabel id="demo-simple-select-outlined-label">Select Product</InputLabel>

        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Select Product"
          multiple
          onChange={e => setProductId(e.target.value)}
          onOpen={() => handleOpenMenuItem()}
          open={closeIcon}
          name="product"
          value={productId?.[0] !== undefined && productId[productId.length - 1] !== undefined ? productId : productId.filter((element, index) => index < productId.length - 1)}
        >
          <IconButton onClick={() => handleCloseMenuItem()} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
          {productListing &&
            productListing.map((item, index) => {
              return (
                <MenuItem
                  value={item._id}
                // onClick={() => getSizeforSameAsProduct(item)}
                >
                  {item.product_name}
                </MenuItem>
              );
            })}
        </Select>
        {errorField === 'product' && <ErrorMessage error={error} />}
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginTop: '16px' }}>
        <Input
          label="Product Modification Name"
          placeholder={'Name'}
          margin="normal"
          variant="outlined"
          name="name"
          // onChange={(e) => setName(e.target.value)}
          onChange={e => handleChange(e)}
          InputProps={{
            classes: {
              underline: classes.InputUnderline,
              input: classes.Input,
            },
          }}
          fullWidth
        />
        {errorField === 'name' && <ErrorMessage error={error} />}
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginTop: '16px' }}>
        <InputLabel id="demo-simple-select-outlined-label">Select Type</InputLabel>
        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Type" onChange={e => setType(e.target.value)} name="type" value={type ? type : ''}>
          {/* {sizeforSameAsProductListing.length > 0 && (
            <MenuItem value={1} onClick={() => clearArrayData()}>
              Same as per product
            </MenuItem>
          )} */}
          <MenuItem value={1} onClick={() => clearArrayData()}>
            Same as per product
          </MenuItem>
          <MenuItem value={2}>Independent of size</MenuItem>
          <MenuItem value={3} onClick={() => clearArrayDataNoSize()}>
            No size
          </MenuItem>
        </Select>
        {errorField === 'type' && <ErrorMessage error={error} />}
      </FormControl>

      {type === 1 && sizeforSameAsProductListing.length > 0 ? (
        <>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginTop: '16px' }}>
            <InputLabel id="demo-simple-select-outlined-label">Select Size of Product</InputLabel>

            <Select labelId="demo-multiple-name-label" id="demo-multiple-name" label="Select Size of Product" onChange={e => handleSameAsProduct(e)} multiple name="sameAsPerProduct" value={sameAsPerProductName}>
              {sizeforSameAsProductListing &&
                sizeforSameAsProductListing.map((item, index) => {
                  return <MenuItem value={item.size}>{item.size}</MenuItem>;
                })}
            </Select>
            {errorField === 'sameAsPerProduct' && <ErrorMessage error={error} />}
          </FormControl>{' '}
        </>
      ) : (
        ''
      )}
      {type == 2 ? (
        <>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginTop: '16px' }}>
            <InputLabel id="demo-simple-select-outlined-label">Select Size</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Size" onChange={e => setIndependentSize(e.target.value)} onClick={e => handleGenCode(e)} name="independentSize" value={independentSize ? independentSize : ''}>
              {sizeforIndependentListing &&
                sizeforIndependentListing.map((item, index) => {
                  return (
                    <MenuItem value={item._id} onClick={() => handleChangeSize(item)}>
                      {item.parameters}
                    </MenuItem>
                  );
                })}
            </Select>
            {errorField === 'independentSize' && <ErrorMessage error={error} />}
          </FormControl>{' '}
        </>
      ) : (
        ''
      )}

      <div style={{ width: '100%', display: 'inline-block' }}>
        {nextData.length > 0 && (
          <>
            {nextData.map((item, i) => (
              <>
                <div className={'sizeDivField'} key={i} style={{ marginRight: '10px', display: 'inline-block' }}>
                  <div>
                    <Box width={100}>
                      <Input name={`${item}`} margin="normal" className={classes.inputNumber} min="1" step="1" placeholder={`${item}`} variant="outlined" type="number" value={sizeData && sizeData[item]} onChange={e => handleInputChange(e, i)} required />
                      {errorField === 'sizeData' && <ErrorMessage error={error} />}
                    </Box>
                  </div>
                </div>
              </>
            ))}{' '}
          </>
        )}
        {nextData.length > 0 && (
          <div>
            {' '}
            <Button onClick={() => handleAddClick()} disabled={Object.keys(sizeData).length !== nextData.length ? true : false}>
              Add
            </Button>
          </div>
        )}

        {isSpecial == 1 && (
          <>
            <Grid container spacing={2} style={{ marginTop: '8px', width: 'auto' }}>
              <Grid item md={6}>
                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Select Fix Size</InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Fix Size"
                    onChange={e => handleFixSize(e)}
                    name="fix_size"
                    multiple
                    value={fixSizeList || []}
                  // onChange={(e) =>
                  //   setFormValues({ ...formValues, status: e.target.value })
                  // }
                  >
                    {fixSize &&
                      fixSize.map((item, index) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                  </Select>
                  {errorField === 'fix_size' && <ErrorMessage error={error} />}
                </FormControl>{' '}
              </Grid>
            </Grid>
          </>
        )}
      </div>

      {/* For Add New Size  */}
      <div>
        {listItem.length > 0 &&
          listItem.map((item, i) => {
            return (
              <>
                <div
                  style={{
                    marginBottom: '5px',
                  }}
                >
                  {Object.keys(item).map(val => {
                    return (
                      <>
                        <div
                          className={'sizeDivField'}
                          key={i}
                          style={{
                            marginRight: '10px',
                            display: 'inline-block',
                          }}
                        >
                          <div>
                            <Box width={100}>
                              <Input name={`${val}`} margin="normal" className={classes.inputNumber} min="1" step="1" placeholder={`${val}`} variant="outlined" type="number" value={[item[val]]} onChange={e => handlelistData(e, i)} required />
                            </Box>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div>
                    <Button onClick={() => handleRemoveClick(i)}>Remove</Button>
                  </div>
                </div>
              </>
            );
          })}
      </div>

      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginTop: '16px' }}>
        <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Accessorie" name="status" onChange={e => setStatus(e.target.value)} value={status}>
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={0}>Deactivated</MenuItem>
        </Select>
        {errorField === 'status' && <ErrorMessage error={error} />}
      </FormControl>

      {/* {type !== 3 && isSpecial != 1 ? ( */}
      <>
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
          <Grid item md={6}>
            <FormControlLabel
              className={classes.input}
              style={{ marginTop: '25px' }}
              InputProps={{
                classes: {
                  underline: classes.InputUnderline,
                  input: classes.Input,
                },
              }}
              control={<Checkbox color="primary" name="is_color_modification" checked={isColorModification == true ? true : false && type == 3} onChange={e => (setIsColorModification(e.target.checked), changeValue(e))} />}
              label="Is color modification?"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
          <Grid item md={6}>
            {!isColorModification && (
              <FormControlLabel
                className={classes.input}
                style={{ width: '100%' }}
                InputProps={{
                  classes: {
                    underline: classes.InputUnderline,
                    input: classes.Input,
                  },
                }}
                control={<Checkbox name="iscustomsize" color="primary" checked={customSize == true ? true : false} onChange={e => setCustomSize(e.target.checked)} />}
                label="Allow Custom Size"
              />
            )}
          </Grid>
          <Grid item md={6} onChange={e => setAllowCustomSize(e.target.value)}>
            {customSize == true ? (
              <>
                <Input id="outlined-number" label="Custom  Size Limit" placeholder={'Custom Size Limit'} margin="normal" variant="outlined" name="allowCustomSize" value={allowCustomSize}></Input>
                {errorField === 'custom_size_limit' && <ErrorMessage error={error} />}
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
          <Grid item md={6}>
            {!isColorModification && (
              <FormControlLabel
                className={classes.input}
                InputProps={{
                  classes: {
                    underline: classes.InputUnderline,
                    input: classes.Input,
                  },
                }}
                control={<Checkbox name="iscustomsizexl" color="primary" checked={customXLSize == true ? true : false} onChange={e => setCustomXlSize(e.target.checked)} />}
                label="Allow Custom XL size"
              />
            )}
          </Grid>
          <Grid item md={6}>
            {customXLSize == true ? (
              <>
                <Input id="outlined-number" label="Custom XL Size Limit" placeholder={'Custom XL Size Limit'} margin="normal" variant="outlined" name="allowCustomXlSize" onChange={e => setAllowCustomXLSize(e.target.value)} value={allowCustomXlSize} ></Input>
                {errorField === 'customxl_size_limit' && <ErrorMessage error={error} />}
              </>
            ) : null}
          </Grid>
        </Grid>
      </>
      {/* ) : (
          ""
        )} */}

      <Grid container spacing={2}>
        <Grid item md={6} style={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            className={classes.input}
            style={{ marginTop: '5px' }}
            InputProps={{
              classes: {
                underline: classes.InputUnderline,
                input: classes.Input,
              },
            }}
            control={<Checkbox color="primary" checked={isModificationRequired == true ? true : false} onChange={e => setIsModificationRequired(e.target.checked)} />}
            label="Is modification required Qty.?"
          />
        </Grid>

        <Grid item md={6} style={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            className={classes.input}
            style={{ width: '100%', marginTop: '16px' }}
            InputProps={{
              classes: {
                underline: classes.InputUnderline,
                input: classes.Input,
              },
            }}
            control={<Checkbox name="iscustomsizexl" color="primary" checked={modificationAllow == true ? true : false} onChange={e => setModificationAllow(e.target.checked)} />}
            label="Modification allow to accessories"
          />
        </Grid>
      </Grid>
      {modificationAllow == true ? (
        <>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginTop: '16px' }}>
            <InputLabel id="demo-simple-select-outlined-label">Select accessories</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Select Accessories"
              multiple
              onChange={e => setAcessoriesId(e.target.value)}
              onOpen={() => handleOpenAssMenuItem()}
              open={AssCloseIcon}
              name="accessories"
              value={acessoriesId?.[0] !== undefined && acessoriesId[acessoriesId.length - 1] !== undefined ? acessoriesId : acessoriesId.filter((element, index) => index < acessoriesId.length - 1)}
            >
              <IconButton onClick={() => handleCloseMenuItem()} style={{ float: 'right' }}>
                <CloseIcon />
              </IconButton>
              {accessoriesListing &&
                accessoriesListing.map((item, index) => {
                  return <MenuItem value={item._id}>{item.name}</MenuItem>;
                })}
            </Select>
            {errorField === 'accessories' && <ErrorMessage error={error} />}
          </FormControl>
        </>
      ) : (
        ''
      )}

      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginTop: '16px' }}>
        <Input
          label="Code"
          placeholder={'Code'}
          margin="normal"
          variant="outlined"
          name="code"
          InputProps={{
            classes: {
              underline: classes.InputUnderline,
              input: classes.Input,
            },
          }}
          onChange={e => setCode(e.target.value)}
          value={code ? code : ''}
          inputProps={{ style: { textTransform: 'uppercase' } }}
          fullWidth
        />
        {errorField === 'code' && <ErrorMessage error={error} />}
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
        <Input
          label="Modification Initals"
          placeholder={'Modification Initals'}
          margin="normal"
          variant="outlined"
          name="modification_initial"
          InputProps={{
            classes: {
              underline: classes.InputUnderline,
              input: classes.Input,
            },
          }}
          onChange={e => setInitals(e.target.value)}
          inputProps={{ style: { textTransform: 'uppercase' } }}
          value={accInitals ? accInitals : ''}
          fullWidth
        />
        {errorField === 'modification_initial' && <ErrorMessage error={error} />}
      </FormControl>

      <Grid container spacing={2}>
        <Grid item md={6} style={{ display: 'flex', alignItems: 'center' }}>
          {/* <FormControlLabel
            className={classes.input}
            style={{ marginTop: "5px" }}
            InputProps={{
              classes: {
                underline: classes.InputUnderline,
                input: classes.Input,
              },
            }}
            control={
              <Checkbox
                color="primary"
                checked={isColorRequired == true ? true : false}
                onChange={(e) => setIsColorRequired(e.target.checked)}
                onClick={clearColorData}
              />
            }
            label="Is color required?"

          /> */}
        </Grid>
      </Grid>

      {isColorRequired == true ? (
        <>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginTop: '16px' }}>
            <InputLabel id="demo-simple-select-outlined-label">Select Color</InputLabel>

            <Select labelId="demo-multiple-name-label" id="demo-multiple-name" label="Select Color" onChange={e => handleColorBox(e)} multiple name="color" value={colours || []}>
              <MenuItem value={'#000000'}>Black</MenuItem>
              <MenuItem value={'#FFFFFF'}>White</MenuItem>
              <MenuItem value={'#0066FA'}>Blue</MenuItem>
              <MenuItem value={'#2CBF6D'}>Green</MenuItem>
              <MenuItem value={'#EFC663'}>Yellow</MenuItem>
              <MenuItem value={'#E16070'}>Red</MenuItem>
              <MenuItem value={'#9677DF'}>Purple</MenuItem>
              <MenuItem value={'#FFA500'}>Orange</MenuItem>
              <MenuItem value={'#FFC0CB'}>Pink</MenuItem>
              <MenuItem value={'#C0C0C0'}>Silver</MenuItem>
            </Select>
            {errorField === 'color' && <ErrorMessage error={error} />}
          </FormControl>
        </>
      ) : (
        ''
      )}

      <InputLabel id="demo-simple-select-outlined-label" style={{ marginTop: '16px', float: 'left', marginRight: '5px' }}>
        Image
      </InputLabel>
      <input
        style={{ marginTop: '16px', marginLeft: '5px', float: 'none' }}
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        name="image"
        onChange={e => handleImage(e)}
        margin="normal"
        variant="outlined"
        InputProps={{
          classes: {
            underline: classes.InputUnderline,
            input: classes.Input,
          },
        }}
      />
      {errorField === 'image' && <ErrorMessage error={error} />}
      <div></div>
      {image ? (
        <img
          src={image ? image : profile}
          style={{
            height: '100px',
            width: '100px',
            marginTop: '20px',
            borderRadius: '20px',
          }}
        />
      ) : (
        <div></div>
      )}
      <DialogActions style={{ padding: '10px 0 20px' }}>
        <Button variant={'outlined'} color="primary" onClick={() => props.ToggleInputModal(false)}>
          Cancel
        </Button>
        <Button variant={'contained'} color="primary" onClick={() => handleSubmit()} disabled={disBtn == true ? true : false}>
          Submit
        </Button>
      </DialogActions>
    </>
  );
}

export default withRouter(AddProductModification);
